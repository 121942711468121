<template>
  <v-select
    v-bind="$attrs"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :items="items"
    clearable
    cache-items
    item-text="name"
    item-value="value"
    class="pa-0 ma-0 selector-default"
    :color="$attrs.dark || $attrs.dark === '' ? 'white' : 'accent'"
  >
    <template v-slot:append-outer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey" size="18" dark v-bind="attrs" v-on="on">
            mdi-help
          </v-icon>
        </template>
        <span>{{ helpText }}</span>
      </v-tooltip>
    </template>

    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        @click="data.select"
        class="selection-default-chip"
        label
      >
        <v-icon left color="primary" v-if="data.item.icon">
          {{ data.item.icon }}
        </v-icon>

        <v-avatar v-else-if="data.item.img" left :size="80">
          <img :src="data.item.img" />
        </v-avatar>

        {{ data.item.name }}
      </v-chip>
    </template>

    <template v-slot:item="data">
      <v-list-item-avatar v-if="data.item.icon || data.item.img">
        <v-icon v-if="data.item.icon" color="primary" left>
          {{ data.item.icon }}
        </v-icon>
        <img v-else-if="data.item.img" :src="data.item.img" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="data.item.name"></v-list-item-title>
        <v-list-item-subtitle
          v-if="data.item.description"
          v-html="data.item.description"
        ></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-select>
</template>
  
  
  <script>
export default {
  props: {
    value: {},
    items: {
      type: Array,
      default: () => [],
    },

    helpText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.selector-default {
  align-items: flex-end;

  .selection-default-chip {
    height: 24px;
  }
  .selector-default-help-icon {
    margin-bottom: 8px;
  }
}
</style>