<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" class="header-editor-col">
        <SelectorServiceStatusVue
          dark
          autoload
          label="Service Status"
          helpText="This is an actual Service Status"
          return-object
          v-model="form.status"
        >
        </SelectorServiceStatusVue>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6" class="header-editor-col">
        <HelpFormInputVue
          dark
          :rows="4"
          v-if="form.searchableKeyword"
          v-model="form.searchableKeyword.name"
          :rules="serviceKeywordsRules"
          label="Keywords"
          :helpText="'This text will be used for the recommendations purpose, please make sure that it is unique across services'"
          required
          :textarea="true"
        ></HelpFormInputVue>

        <!-- Slides Configurations -->
        <FormSection
          :label="'Slides'"
          :icon="'mdi-play-box-outline'"
          underline
          :editable="false"
          right
        ></FormSection>

        <SelectorDefault
          dark
          class="mt-4"
          label="Slide Image"
          v-model="form.slide_image_key"
          :items="slideImages"
          :helpText="'Please Select an Image that will be used in the presentations'"
          required
          clearable
        ></SelectorDefault>
      </v-col>
    </v-row>
  </v-form>
</template>
  
  
  <script>
import SelectorDefault from "../../../atoms/common/autocompletes/SelectorDefault.vue";
import SelectorServiceStatusVue from "../../../atoms/common/autocompletes/SelectorServiceStatus.vue";
import FormSection from "../../../atoms/common/FormSection.vue";

import HelpFormInputVue from "../../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,

      // ======rules
      serviceKeywordsRules: [(v) => !!v || "Keywords are required"],

      slideImages: [
        {
          value: "Google Shape;210;p39",
          name: "Google Shape;210;p39",
          img: "/services-slides/210.png",
        },
        {
          value: "Google Shape;211;p39",
          name: "Google Shape;211;p39",
          img: "/services-slides/211.png",
        },
        {
          value: "Google Shape;212;p39",
          name: "Google Shape;212;p39",
          img: "/services-slides/212.png",
        },
        {
          value: "Google Shape;213;p39",
          name: "Google Shape;213;p39",
          img: "/services-slides/213.png",
        },
        {
          value: "Google Shape;214;p39",
          name: "Google Shape;214;p39",
          img: "/services-slides/214.png",
        },
        {
          value: "Google Shape;215;p39",
          name: "Google Shape;215;p39",
          img: "/services-slides/215.png",
        },
        {
          value: "Google Shape;216;p39",
          name: "Google Shape;216;p39",
          img: "/services-slides/216.png",
        },
        {
          value: "Google Shape;217;p39",
          name: "Google Shape;217;p39",
          img: "/services-slides/217.png",
        },
        {
          value: "Google Shape;218;p39",
          name: "Google Shape;218;p39",
          img: "/services-slides/218.png",
        },
        {
          value: "Google Shape;219;p39",
          name: "Google Shape;219;p39",
          img: "/services-slides/219.png",
        },
      ],
    };
  },
  components: {
    HelpFormInputVue,
    SelectorServiceStatusVue,
    SelectorDefault,
    FormSection,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.form = newVal;
      },
      deep: true,
    },
    form: {
      handler(newVal) {
        this.form.previous_service_id = newVal?.prevService?.id;
        this.form.next_service_id = newVal?.nextService?.id;

        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
  
  
  <style lang="scss" scoped>
.header-editor-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>