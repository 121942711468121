import { render, staticRenderFns } from "./SelectorServiceStatus.vue?vue&type=template&id=2a84a719&scoped=true&"
import script from "./SelectorServiceStatus.vue?vue&type=script&lang=js&"
export * from "./SelectorServiceStatus.vue?vue&type=script&lang=js&"
import style0 from "./SelectorServiceStatus.vue?vue&type=style&index=0&id=2a84a719&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a84a719",
  null
  
)

export default component.exports