<template>
  <AView
    :value="value"
    :actions="actions"
    :name="service.name"
    :active="service.id === activeServiceId"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onSave="onSave"
    @onClear="onClear"
  >
    <template v-slot:content="{}">
      <ServiceGeneralForm
        ref="form"
        class="pt-4"
        v-model="service"
        @onServiceClick="onServiceClick"
      ></ServiceGeneralForm>
    </template>
  </AView>
</template>
    
    <script>
import { mapGetters, mapState } from "vuex";
import AView from "../../../../../../../components/wad/organisms/layout/A-View.vue";
import ServiceGeneralForm from "../../../../../../../components/wad/organisms/services/forms/ServiceGeneralForm.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    ServiceGeneralForm,
  },
  data() {
    return {
      actions: [
        {
          name: "Save",
          description: "Will save changes",
          icon: "mdi-check",
          color: "#23842a",
          on: "onSave",
        },
        {
          name: "Close",
          description: "Will clear the form",
          icon: "mdi-close",
          color: "#0e1721",
          on: "onClear",
        },
      ],
      service: {},
    };
  },
  created() {
    this.$store.dispatch("ServiceStore/GetService", {
      id: this.value.relation.params.serviceId,
    });

    
    this.service = this.serviceById(this.value.relation.params.serviceId);
  },

  computed: {
    ...mapState("ServiceStore", ["activeServiceId", "displayedServices"]),
    ...mapGetters("ServiceStore", ["serviceById"]),
  },

  methods: {
    onHover() {
      this.$store.commit(
        "ServiceStore/setActiveId",
        this.value.relation.params.serviceId
      );
    },

    onServiceClick(service) {
      this.$store.dispatch("ServiceStore/openServiceDetails", {
        from: this.value,
        service,
      });
    },
    onFocus(data) {
      console.log("=============Data: ", data);
    },
    async onSave() {
      if (this.$refs.generalForm.validate()) {
        this.$store.dispatch("ServiceStore/onServiceSave", this.service);
      }
    },
    onClear() {},
  },
  watch: {
    displayedServices: {
      async handler(newVal) {
        if (newVal && !newVal.IN_PROGRESS) {
          this.service = this.serviceById(this.value.relation.params.serviceId);
        }
      },
      deep: true,
    },
    service: {
      async handler(newVal, oldVal) {
        if (!(oldVal && oldVal.id)) return;

        if (this.$refs.form && this.$refs.form.validate())
          await this.$store.dispatch("ServiceStore/UpdateService", {
            service: newVal,
          });
      },
      deep: true,
    },
  },
};
</script>